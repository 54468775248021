.largePowerLevel {
    flex: 0.5;
    position: relative;
}
.powerLevelText {
    position: absolute;
    top: 40%;
    width: 100%;
    text-align: center;
    font-size: 40px;
    font-weight: 900;
}
.largeVisualizationCloseButton {
    appearance: none;
    background: none;
    border: none;
    color: white;
    z-index: 99999;
    position: fixed;
    top: 24px;
    right: 24px;
    font-size: 40px;
}
.largeVisualizationCloseButton:hover {
    cursor: pointer;
}

.rotate {
  animation-duration: 2s;
  animation-name: rotate;
  animation-iteration-count: infinite;
}
.rotate2 {
    animation: 3s linear 1s infinite alternate rotate;
}
.rotate3 {
    animation: 1s linear 1s infinite reverse rotate;
}
.rotate4 {
    animation: 4s linear 1s infinite running rotate;
}

@keyframes rotate {
  from {
    transform: rotate(0)
  }

  to {
    transform: rotate(359deg)
  }
}
