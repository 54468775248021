.blink {
  animation: 1s linear infinite blink;
}

@keyframes blink {
  0% {
    opacity: 1;
  }
  48% {
    opacity: 1
  }
  50% {
    opacity: 0;
  }
  60% {
    opacity: 0;
  }
  70% {
    opacity: 1
  }
  100% {
    opacity: 1;
  }
}

.Terminal__container {
  color: #f8f8f8;
  font-size: 15px;
  line-height: 1.5;
  font-family: rooney-web, monospace;
  font-weight: 400;
  height: 100%;
  width: 100%;
  min-height: 300px;
  position: relative;
  padding-bottom: 70px;
  opacity: 0.8;
}

@media screen and (max-width: 540px) {
  .Terminal__container {
    padding-bottom: 90px;
    font-size: 12px;
  }
}
