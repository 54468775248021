html {
    background-color: #333;
}

.pointer:hover {
    cursor: pointer;
}

.fadeIn {
    animation: fadein 12s;
}

.fadeInSlow {
    animation: fadein 30s ease-in;
}

.fadeInSlight {
    opacity: 0.2;
    transform: scale(2);
    animation: fadeinslight 20s ease-in-out;
}

@keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

@keyframes fadeinslight {
    from { opacity: 0; transform: scale(1); }
    to   { opacity: 0.2; transform: scale(2); }
}

.grow {
    animation: grow 25s ease-in, fadein 5s ease-in;
    transform: scale(2.5);
}
@keyframes grow {
  from {
    transform: scale(1);
  }
  to {
    transform: scale(2.5);
  }
}

.pageContainer {
  padding: 40px 16px;
}

@media screen and (max-width: 500px) {
  .pageContainer {
    padding: 0 16px;
  }
}

.largeScreen {
  display: block;
}
.smallScreen {
  display: none;
}

@media screen and (max-width: 500px) {
  .largeScreen {
    display: none;
  }
  .smallScreen {
    display: block;
  }
}

.noTapHighlight {
  -webkit-tap-highlight-color:  rgba(255, 255, 255, 0);
}

.fullScreenBackgroundVideo {
  object-fit: fill;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  zIndex: 0;
}

.saidAsSystem {
  color: #f8f8f8;
  font-size: 24px;
  line-height: 1.5;
  font-family: t26-carbon, monospace;
  font-weight: 100;
}
