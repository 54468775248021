.animate-out {
    animation-duration: 2s;
    animation-name: fadeToPersonalityCorner;
}

@keyframes fadeToPersonalityCorner {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}
